window.addEventListener('load', event => {
	const body = document.querySelector('body');
	const header = document.querySelector('header');
	const menuToggle = document.querySelector('#menu-toggle');
	if (menuToggle) {
		menuToggle.addEventListener('click', () => {
			body.classList.toggle('has-overlay');
			header.classList.toggle('mobile-nav-active');
		});
	}

	var headroomHeader = document.querySelector('header');
	// construct an instance of Headroom, passing the element

	if (headroomHeader) {
		var headroom = new Headroom(headroomHeader, {
			tolerance: {
				down: 0,
				up: 10
			},
			offset: 150
		}).init();
	}
});

$(document).ready(function () {
	$('ul.fund-details-tabs li').click(function () {
		var tab_id = $(this).attr('data-tab');

		$('ul.fund-details-tabs li').removeClass('current');
		$('.tab-content').removeClass('current');

		$(this).addClass('current');
		$('#' + tab_id).addClass('current');
	});
});

//Dropdown

function DropDown(el) {
	this.dd = el;
	this.placeholder = this.dd.children('span');
	this.opts = this.dd.find('ul.dropdown > li');
	this.val = '';
	this.index = -1;
	this.initEvents();
}
DropDown.prototype = {
	initEvents: function () {
		var obj = this;

		obj.dd.on('click', function (event) {
			$(this).toggleClass('active');
			return false;
		});

		obj.opts.on('click', function () {
			var opt = $(this);
			obj.val = opt.text();
			obj.index = opt.index();
			obj.placeholder.text(obj.val);
		});
	},
	getValue: function () {
		return this.val;
	},
	getIndex: function () {
		return this.index;
	}
};

// Newsletter popup
let captchaSuccess = false;
document.addEventListener('DOMContentLoaded', () => {
	const popup = document.querySelector('#newsletter-popup');
	const newsletterModal = document.querySelector('.newsletter-modal');
	if (popup) {
		const closeElems = document.querySelectorAll('.newsletter-close');
		const newsletterSubmitButton = document.querySelector('#newsletter-submit');
		closeElems.forEach(elem => {
			elem.addEventListener('click', () => {
				popup.classList.add('popup-hidden');
				document.cookie = 'newsletterPopupClosed=true; max-age=5184000; path=/'; // Drop cookie that expires after 2 months
			});
		});
		newsletterSubmitButton.addEventListener('click', () => {
			document.cookie = 'newsletterSubscribed=true; expires=Fri, 31 Dec 2030 23:59:59 GMT; path=/';
		});
		const options = {
			rootMargin: '0px',
			threshold: 1.0
		};
		const callback = (entries, observer) => {
			entries.forEach(entry => {
				// Check if popup is intersecting viewport
				if (entry.isIntersecting) {
					// Check if user has subscribed
					if (!checkIfCookieExists('newsletterSubscribed')) {
						// Check if portal selector cookie has been closed (to avoid stacking elements)
						if (checkIfCookieExists('PortalSelector')) {
							// Check if cookie exists
							if (!checkIfCookieExists('newsletterPopupClosed')) {
								observer.unobserve(target); // Remove observer
								popup.classList.remove('popup-hidden'); // Show popup
							}
						}
					}
				}
			});
		};
		// Intersection observer to trigger popup
		const observer = new IntersectionObserver(callback, options);
		const target = document.querySelector('.article-bottom-controls');
		if (target) {
			observer.observe(target);
		}
	}
	if (newsletterModal) {
		const closeModalElems = document.querySelectorAll(
			'.newsletter-modal-close .newsletter-close-button, .newsletter-modal-overlay'
		);
		if (closeModalElems) {
			closeModalElems.forEach(elem => {
				elem.addEventListener('click', () => {
					document.cookie = 'newsletterPopupClosed=true; max-age=5184000; path=/'; // Drop cookie that expires after 2 months
					newsletterModal.classList.add('newsletter-modal-hidden');
					document.querySelector('body').classList.remove('body-overflow-hidden'); // Show page scrollbar
				});
			});
		}
	}

	// Open Newsletter Elems
	const openNewsletterModalElems = document.querySelectorAll('.newsletter-subscribe-button');
	if (openNewsletterModalElems) {
		openNewsletterModalElems.forEach(elem => {
			elem.addEventListener('click', () => {
				openNewsletterModal();
			});
		});
	}

	function openNewsletterModal() {
		if (popup) {
			popup.classList.add('popup-hidden');
		} // Hide popup
		newsletterModal.classList.remove('newsletter-modal-hidden'); // Show modal
		document.querySelector('body').classList.add('body-overflow-hidden'); // Hide page scrollbar
	}

	function checkIfCookieExists(CookieName) {
		if (document.cookie.split('; ').find(row => row.startsWith(CookieName))) {
			return true;
		}
	}

	// Show popup on insights landing if load more has been clicked more than once
	const insightsLoadMoreButton = document.querySelector('.load-more');
	if (insightsLoadMoreButton) {
		insightsLoadMoreButton.addEventListener('click', () => {
			if (!checkIfCookieExists('newsletterSubscribed')) {
				if (checkIfCookieExists('PortalSelector')) {
					if (!checkIfCookieExists('newsletterPopupClosed')) {
						if (document.querySelectorAll('.post-list .item').length > 10) {
							setTimeout(() => {
								popup.classList.remove('popup-hidden'); // Show popup
							}, 2500);
						}
					}
				}
			}
		});
	}

	// Search bar
	let searchIsOpen = false;
	const bodyOverlay = document.querySelector('.body-overlay');
	const searchToggle = document.querySelector('#search-toggle');
	const searchInput = document.querySelector('.search-input-wrapper');
	const searchInputControl = document.querySelector('.search-input-wrapper input');
	const searchButton = document.querySelector('.search-input-wrapper button');
	const elems = [bodyOverlay, searchToggle, searchInput, searchInputControl, searchButton];
	if (searchToggle) {
		searchToggle.classList.remove('loading');
		searchToggle.addEventListener('click', () => {
			searchIsOpen = !searchIsOpen;
			setSearchState();
		});
	}
	document.addEventListener('click', e => {
		if (elems.some(item => e.target == item)) {
			// if one of search elems clicked do nothing
		} else {
			searchIsOpen = false; // close search popup on any other click target
			setSearchState();
		}
	});
	document.addEventListener('keydown', event => {
		if (event.key === 'Escape' && searchIsOpen) {
			searchIsOpen = false; // escape key closes popup
			setSearchState();
		}
	});
	function setSearchState() {
		if (searchIsOpen) {
			elems.map(item => item.classList.add('active')); // add active class
			setTimeout(() => {
				searchInputControl.focus(); // focus input
			}, 100);
		} else {
			elems.map(item => item.classList.remove('active')); // remove active class
			searchInputControl.blur(); // remove focus
		}
	}

	// Country nav toggle
	const countryToggle = document.querySelector('#country-toggle')
	const countryMenu = document.querySelector('#country-menu')
	let countryMenuIsOpen = false

	if (countryToggle && countryMenu) {
		document.addEventListener('click', (e) => {
			if (e.target === countryToggle) {
				// If target is country selector dropdown toggle state
				countryMenuIsOpen = !countryMenuIsOpen
				setCountryState()
			}
			else if (!countryMenuIsOpen) {
				// If menu is closed do nothing
				return false
			} else {
				if (e.target !== countryMenu) {
					// Else close menu if user clicks elsewhere
					countryMenuIsOpen = false
					setCountryState()
				}
			}
		})

		function setCountryState() {
			if (countryMenuIsOpen) {
				[countryMenu, countryToggle].forEach(elem => elem.classList.add('open'))
			} else {
				[countryMenu, countryToggle].forEach(elem => elem.classList.remove('open'))
			}
		}
	}

});

// insight image modal script
window.addEventListener("load", function () {

	// check if on an insight page
	const insightPageContainer = document.querySelector(".article-detail-block");
	if (!insightPageContainer) { return };

	// insight page elements
	const pageContainer = insightPageContainer.closest(".wrapper"); 
	const imageModal = pageContainer.querySelector(".insight-image-modal");
	const modalImage = imageModal.querySelector(".modal-image");
	const modalClose = imageModal.querySelector(".modal-close")
	const insightImages = pageContainer.querySelectorAll(".imageZoom");

	// add event listener to each image
	for (let image of insightImages) {
		const imageSource = image.querySelector("img").src;

		image.addEventListener("click", function () {
			modalImage.src = imageSource.split('?')[0];
			imageModal.classList.remove("modal-hide");
			imageModal.classList.add("modal-show");
		});
	}

	modalClose.addEventListener("click", function () {
		modalImage.src = "";
		imageModal.classList.add("modal-hide");
		imageModal.classList.remove("modal-show");
	});

	// listen for escape key
	document.addEventListener("keydown", function (e) {
		if (e.key === "Escape" || e.key === "Esc") {

			// close modal if open			
			if (imageModal.classList.contains("modal-show")) {
				modalImage.src = "";
				imageModal.classList.add("modal-hide");
				imageModal.classList.remove("modal-show");
			};
		};
	});

}, false);


