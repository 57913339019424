const activeTab = document.querySelector("#content-selected-tab");

if (activeTab) {
	scrollTo(activeTab);
}

// Scrolls to tab in horizontal tab list
function scrollTo(el) {
	const elLeft = el.offsetLeft + el.offsetWidth;
	const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth;
	if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
		el.parentNode.scrollLeft = elLeft + 30 - elParentLeft;
	} else if (elLeft <= el.parentNode.offsetLeft + el.parentNode.scrollLeft) {
		el.parentNode.scrollLeft = el.offsetLeft - el.parentNode.offsetLeft;
	}
}
